<template>
  <main class="view-challenge">
    <div
      class="user__page"
      id="sayl-challenge-page-resto"
      v-if="!loading"
    ></div>

    <div
      class="user__loader"
      v-else>
      <ui-loader />
    </div>
  </main>
</template>

<script>
import MixinChallenge from './mixin.js'
import { loadScript } from '@/helpers/loadscript.js'

export default {
  name: 'ViewChallenge',

  mixins: [ MixinChallenge ],

  computed: {
    value() {
      return {
        completionsCount: this.$challenge.challenge.completionsCount,
        description: this.$challenge.challenge.description,
        summary: this.$challenge.challenge.descriptionMini,
        from: this.$challenge.challenge.from ? new Date(this.$challenge.challenge.from).toISOString() : null,
        globalAmountRewards: this.$challenge.challenge.globalAmountRewards,
        key: this.$challenge.challenge.id,
        logo: this.$basil.get(this.$challenge.challenge, 'images[0].url'),
        reward: this.$challenge.challenge.rewardDescr,
        settings: this.$challenge.challenge.rewarrdTypeSettings,
        title: this.$challenge.challenge.name,
        to: this.$challenge.challenge.to ? new Date(this.$challenge.challenge.to).toISOString() : null,
        type: this.$challenge.challenge.rewardType,
        userAmountRewards: this.$challenge.challenge.userAmountRewards,
        userCompletionsCount: this.$challenge.challenge.userCompletionsCount,
        history: this.completions,
      }
    },
  },

  methods: {
    reset() {
      this.loading = true

      this.$challenge.getSettings()
        .then(() => this.$challenge.view({ id: this.$route.params.id }))
        .then(() => this.isLogged ? this.$challenge.getCompletionsByChallenge({ id: this.$route.params.id }) : Promise.resolve())
        .catch((e) => $console.error(e))
        .finally(() => {
          this.loading = false
          setTimeout(() => loadScript(this.scriptUrl, this.scriptLoaded), 50)
        })
    },
  },
}
</script>
